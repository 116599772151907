import './hours.css'

export const Hours = () => {
    return (
        <div className='hours-container'>
            <p>Closed Sunday & Monday</p>
            <p>Tuesday: 10am-4pm</p>
            <p>Wednesday: 12pm-8pm</p>
            <p>Thursday: 12pm-5pm</p>
            <p>Friday: 10am-4pm</p>
            <p>Saturday: 9am-3pm</p>
            <p>(By appointment only)</p>
        </div>
    );
}